import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCookie } from "./utilKumho.js";
function CheckLogin() {
  const history = useHistory();
  const EmpNm = useCookie();

  const chkCookie = () => {
    if (EmpNm[0] == null) {
      console.log("EmpNm is null");
      //대충 여기에 Empnm을 쓸 경우를 주입.
      //전혀 동작할 일이 없지 않나?
    } else {
      //console.log(EmpNm + "이펙트 내부");
      chkLogin2(EmpNm);
    }
  };

  const chkLogin2 = (EmpNm) => {
    //console.log(EmpNm);
    if (EmpNm[0] != 0) {
    } else {
      alert("Login Plz!!");
      history.push("/login");
    }
  };
  useEffect(chkCookie, [EmpNm]);
  //console.log(Empnm);
  return <div></div>;
}

export default CheckLogin;
