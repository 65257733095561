import { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";

import {
  ClassicEditor,
  AccessibilityHelp,
  Autoformat,
  Autosave,
  Alignment,
  BalloonToolbar,
  BlockToolbar,
  Bold,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  ImageBlock,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Italic,
  Link,
  LinkImage,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  SimpleUploadAdapter,
  SourceEditing,
  Strikethrough,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  TextTransformation,
  Underline,
  Undo,
} from "ckeditor5";
//import { CustomFontSizeDropdown } from "./customPlugin/customfontsize";

import translations from "ckeditor5/translations/ko.js";
import { customUploadAdapter } from "../../utils/utilKumho";
import "ckeditor5/ckeditor5.css";

import "./CkEditor5.css";

export default function CkEditor5({ onChange, content }) {
  const editorContainerRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [editorData, setEditorData] = useState("");

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      //loader.file.then((file) => console.log(file)); //디버깅 loader는 promise로 들어옴
      return customUploadAdapter(loader);
    };
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
    //부모 컴포넌트의 값이 있을경우
    if (onChange) {
      onChange(data);
    }
  };

  useEffect(() => {
    setIsLayoutReady(true);
    if (content) setEditorData(content);

    return () => setIsLayoutReady(false);
  }, [content]);

  const editorConfig = {
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "sourceEditing",
        "findAndReplace",
        "selectAll",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "alignment",
        "|",
        "link",
        "insertImage",
        "mediaEmbed",
        "insertTable",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      Alignment,
      LinkImage,
      AccessibilityHelp,
      Autoformat,
      Autosave,
      BalloonToolbar,
      BlockToolbar,
      Bold,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      ImageBlock,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Italic,
      Link,
      MediaEmbed,
      Paragraph,
      PasteFromOffice,
      SelectAll,
      SimpleUploadAdapter,
      SourceEditing,
      Strikethrough,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      TextTransformation,
      Underline,
      Undo,
    ],
    extraPlugins: [uploadPlugin],
    balloonToolbar: ["bold", "italic", "|", "link", "insertImage"],
    blockToolbar: [
      "fontSize",
      "fontColor",
      "fontBackgroundColor",
      "|",
      "bold",
      "italic",
      "|",
      "link",
      "insertImage",
      "insertTable",
    ],
    fontFamily: {
      //supportAllValues: true,
      options: [
        "default",
        "궁서체",
        "바탕",
        "돋움체",
        "맑은고딕", // 추가할 폰트
      ],
    },
    fontSize: {
      options: [10, 11, 12, 13, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    image: {
      toolbar: [
        "linkImage",
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    mediaEmbed: {
      previewsInData: true,
    },
    initialData: "",
    language: "ko",
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    placeholder: "내용을 기입해주세요!!",
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
    translations: [translations],
  };

  return (
    <div>
      <div>
        <div ref={editorContainerRef}>
          <div className="editor-container__editor ">
            <div ref={editorRef}>
              {isLayoutReady && (
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfig}
                  data={editorData}
                  onChange={handleEditorChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
