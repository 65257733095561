import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useCookie } from "../utils/utilKumho.js";
import Modals from "./board/Modals.js";
import axios from "axios";
import { APIURL } from "../utils/utilKumho.js";
function Navbar() {
  const Empinfo = useCookie();
  const [bdtype, getBDtype] = useState([]);
  const [authYn, getAuthYn] = useState();
  const [loading, getLoading] = useState(true);
  // console.log(authYn);
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (Empinfo.length > 0) getAuth();
  }, [Empinfo[0]]);

  async function getData() {
    try {
      const response = await axios.get(APIURL("getBoard"), {
        params: {
          type: "BDType",
        },
      });
      getBDtype(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      //console.log("finish");
    }
  }
  async function getAuth() {
    try {
      const res = await axios.get(APIURL("getBoard"), {
        params: { type: "BDAuth", param: Empinfo[0] },
      });
      if (res.data.length > 0) getAuthYn(res.data[0].auth);
    } catch (error) {
      console.error(error);
    } finally {
      getLoading(false);
    }
  }

  return (
    <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-danger">
      <div className="container-fluid">
        <Link to="/">
          <img
            src="/kheinstar.png"
            className="m-1"
            width="40px"
            height="40px"
            placeholder="InstargramIMG"
            alt="KHE"
          />
        </Link>
        <Link className="navbar-brand" to="/">
          <strong>와글와글</strong>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav me-auto mb-2 mb-md-0">
            {bdtype.map((typedata) => (
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  to={`/List/${typedata.board_idx}`}
                >
                  {typedata.board_name}
                </Link>
              </li>
            ))}
            {
              loading ? (
                <div></div>
              ) : authYn === "Y" ? (
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/Admin"
                  >
                    관리자페이지
                  </Link>
                </li>
              ) : null //관리자 테스트 페이l지
            }
          </ul>
          <div className="d-flex me-4">
            {
              Empinfo[0] === "30338" ? (
                <Modals cls="btn-success" btnvalue="게시판등록" />
              ) : null //관리자 테스트 페이지
            }
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
