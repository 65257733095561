import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BoardWrite from "./BoardWrite.js";
import Sgv from "../../assets/Sgv.js";
function Modals({ boardidx, boardseq, modify, btnvalue, cls, parenfunc }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    if (parenfunc) parenfunc();
  };
  const handleShow = () => setShow(true);

  return (
    <div>
      <Button
        variant={`btn ${cls}`}
        onClick={(e) => {
          handleShow();
        }}
      >
        <Sgv type="pencil" /> {btnvalue}
      </Button>

      <Modal show={show} onHide={handleClose} size="xl" enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modify === "Y" ? "게시물 수정" : "와글와글 게시물 작성"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BoardWrite
            boardidx={boardidx}
            boardseq={boardseq}
            childfunc={(e) => {
              handleClose();
            }}
            modify={modify}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Modals;
