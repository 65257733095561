import NavBar from "./NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/CSS/carousel.css";
import "../assets/CSS/View.css";
import CommentBox from "./board/CommentBox.js";
import CheckLogin from "../utils/CheckLogin.js";
import { Link } from "react-router-dom";

function RelayToon3() {
  return (
    <div>
      <CheckLogin />
      <header>
        <NavBar />
      </header>
      <main>
        <div
          id="myCarousel test"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="photo/RelayToon/RelayToon.png"
                width="100%"
                height="100%"
              />
              <div className="container">
                <div className="carousel-caption text-bg-light vw-ct-title">
                  <div className="vw-top text-start tit1">
                    <span className="ms-5">릴레이툰 #구매#물류</span>
                  </div>
                  <div className="center">
                    <h1 className="vw-title">
                      금호전기 릴레이툰(구매물류팀)💪!!!!
                    </h1>
                  </div>
                  <div className="vw-title-right text-center tit2">2024.06</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators"></div>
        </div>
        <div className="container marketing">
          <div className="row text-center">
            <div className="col-12">
              <Link className="btn btn-danger" to={`/Relaytoon2`}>
                이전작
              </Link>
              <Link className="btn btn-success ms-3" to={`/Relaytoon4`}>
                다음작
              </Link>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div>
                <div className="col-12">
                  <span>
                    구매물류팀 이어나간 릴레이툰을 감상하시죠!! 💪💪💪💪
                    <br />
                    그린순서 (1번 강경훈 사원, 2번 이종훈 사원, 3번 이창락 과장,
                    4번 서덕현 대리, 5번 김주현 과장)
                  </span>
                </div>
                <br />
                <img
                  src="photo/RelayToon/storage.jpg"
                  width="100%"
                  height="100%"
                  className="rounded border"
                />
                <div className="mt-2 mb-5">
                  <span>다음 릴레이툰 주자는 디자인팀입니다!!👩‍🎨!!!</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <CommentBox boardidx="0002" boardseq="3" />
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
}

export default RelayToon3;
