import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useCookie, APIURL } from "../../utils/utilKumho";

function CmmView({ board_idx, board_seq, refT }) {
  const [Cmm, setCmm] = useState([]);
  const empno = useCookie();

  useEffect(() => {
    getData();
  }, [refT, board_seq]);

  async function getData() {
    try {
      const response = await axios.get(
        //"http://api.raycure.co.kr:3838/SaleDayData/getCmmData/",
        APIURL("getCmm"),
        {
          params: {
            boardidx: board_idx,
            boardseq: board_seq,
          },
        }
      );

      setCmm(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  function ChkDel(cmt_idx) {
    var response = window.confirm("삭제하겠습니까?");
    //console.log(response);
    if (response) handleDelete(cmt_idx);
  }

  async function handleDelete(cmt_idx) {
    try {
      await axios.delete(APIURL("delCmm"), {
        params: {
          board_idx: board_idx,
          board_seq: board_seq,
          cmt_idx: cmt_idx,
        },
      });
      setCmm((prevCmm) => prevCmm.filter((cmt) => cmt.cmt_idx !== cmt_idx));
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="container mt-3">
      {Cmm.length === 0 ? (
        <p className="text-center">등록된 댓글이 없습니다.</p>
      ) : (
        Cmm.map((cmt) => (
          <div
            className="d-flex justify-content-between align-items-center border-bottom py-2"
            key={cmt.cmt_idx}
          >
            <div className="d-flex flex-column col-10 pe-1">
              <span className="fw-bold" style={{ fontSize: "1rem" }}>
                {cmt.cmtWriter}
              </span>
              <span style={{ fontSize: "0.875rem" }}>{cmt.comment}</span>
            </div>
            <div className="col-1">
              {cmt.cmtWriterNo === empno[0] ? (
                <a
                  className="text-muted"
                  style={{
                    fontSize: "0.75rem",
                    color: "#6c757d",
                    cursor: "pointer",
                  }}
                  onClick={() => ChkDel(cmt.cmt_idx)}
                >
                  삭제
                </a>
              ) : (
                <span
                  className="text-muted"
                  style={{ fontSize: "0.75rem", color: "#6c757d" }}
                ></span>
              )}
            </div>
            <div className="col-1">
              <span
                className="text-muted"
                style={{ fontSize: "0.75rem", color: "#6c757d" }}
              >
                {cmt.regdate
                  ? moment.utc(cmt.regdate).format("YYYY-MM-DD")
                  : ""}
              </span>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default CmmView;
