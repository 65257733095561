import NavBar from "../routes/NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/CSS/carousel.css";
import "../assets/CSS/View.css";
import CommentBox from "./board/CommentBox.js";
import CheckLogin from "../utils/CheckLogin.js";
import { Link } from "react-router-dom";

function RelayToon() {
  return (
    <div>
      <CheckLogin />
      <header>
        <NavBar />
      </header>
      <main>
        <div
          id="myCarousel test"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class={`carousel-item active`}>
              <img
                src="photo/RelayToon/RelayToon.png" //"photo/1.jpg"}
                width="100%"
                height="100%"
              />

              <div class="container">
                <div class="carousel-caption text-bg-light vw-ct-title">
                  <div class="vw-top text-start tit1">
                    <span class="ms-5">릴레이툰 #인사총무#전산</span>
                  </div>
                  <div class="center">
                    <h1 class="vw-title">
                      금호전기 릴레이툰(인사총무/전산팀)😁!!!!
                    </h1>
                  </div>
                  <div class="vw-title-right text-center tit2">2024.06</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators"></div>
        </div>
        <div class="container marketing">
          <div class="row text-center">
            <div class="col-12">
              <button class="btn btn-secondary" disabled>
                이전작
              </button>
              <Link class="btn btn-success ms-3" to={`/Relaytoon2`}>
                다음작
              </Link>
            </div>
          </div>
          <div class="row">
            <div class="position-relative col-lg-12">
              <div class="position-absolute">
                <div class="col-12">
                  <span>
                    인사총무 전산팀이 이어나간 릴레이툰을 감상하시죠!! 😋
                    <br />
                    그린순서 (1번 이형귀과장, 2번 김형순대리, 3번 류재혁사원,
                    4번 김혜미대리, 5번 김나현사원)
                  </span>
                </div>
                <br />

                <img
                  src="photo/RelayToon/HRTechTeam.jpg"
                  width="100%"
                  height="100%"
                  class="rounded border"
                />
                <div class="mt-2 mb-5">
                  <span>다음 릴레이툰 주자는 재경팀입니다😄!!!</span>
                </div>
                <div class="col-12">
                  <CommentBox boardidx="0002" boardseq="1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
}
export default RelayToon;
