import NavBar from "./NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/CSS/carousel.css";
import "../assets/CSS/View.css";
import CommentBox from "./board/CommentBox.js";
import { Link } from "react-router-dom";

function Section({
  num,
  name,
  li1,
  li2,
  li3,
  star,
  n_href,
  img1_url,
  img2_url,
}) {
  return (
    <div>
      <div className="col-12 mb-3">
        <span>
          <strong>
            ({num}) {name}
          </strong>
          <ul>
            <li>{li1}</li>
            <li>{li2}</li>
            <li>{li3}</li>
          </ul>
          {star}
          <br />
        </span>
        <a className="btn btn-success" target="_blank" href={n_href}>
          {`${name} 네이버지도 보기`}
        </a>
      </div>
      <div className="d-flex flex-wrap">
        <img
          src={img1_url}
          className="rounded border col-12 col-md-5 me-3 img-fluid"
        />
        <img
          src={img2_url}
          className="rounded border col-12 col-md-5 img-fluid"
        />
      </div>
    </div>
  );
}

function Tasty2() {
  return (
    <div>
      <header>
        <NavBar />
      </header>
      <main>
        <div
          id="myCarousel test"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="photo/Michelin/Michelin.jpg"
                width="100%"
                height="100%"
                className="photocut"
              />
              <div className="container">
                <div className="carousel-caption text-bg-light vw-ct-title">
                  <div className="vw-top text-start tit1">
                    <span className="ms-5">맛집 추천 #맛집 #금쉐린 #야미</span>
                  </div>
                  <div className="center">
                    <h1 className="vw-title">
                      금호전기⚡ 여의도 점심 맛집 추천 리스트 2탄 👨‍🍳👨‍🍳🍟🍕🍱!!!!
                    </h1>
                  </div>
                  <div className="vw-title-right text-center tit2">
                    2024.07.26
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators"></div>
        </div>
        <div className="container marketing">
          <div className="row mt-4">
            <div className="col-lg-12">
              <div>
                <div className="col-12">
                  <span>
                    늘 고르기 힘든 점심메뉴...여러분께 추천드립니다. 여의도 점심
                    맛집 추천 리스트...! 감상하시죠!! 👨‍🍳
                    <br />
                  </span>
                </div>
                <br />
                <Section
                  num="1"
                  name="옥된장"
                  li1="우삼겹, 우렁 선택가능. 미나리가 올라간 된장찌개"
                  li2="밑반찬 고기김치찜이 압권!"
                  li3="2공기는 먹게되는 맛집"
                  star="⭐⭐⭐⭐"
                  n_href="https://naver.me/5atAmmLD"
                  img1_url="photo/Michelin/2-1-1.jpg"
                  img2_url="photo/Michelin/2-1-2.jpg"
                />
                <Section
                  num="2"
                  name="별미볶음"
                  li1="저렴한 가성비,푸짐한 양, 불맛까지 훌륭한 맛집"
                  li2="매큼하고 계란찜까지 시키면 금상첨화!"
                  li3="1호점은 줄이 길어 2호점 추천."
                  star="⭐⭐⭐⭐"
                  n_href="https://naver.me/I5F4bo42"
                  img1_url="photo/Michelin/2-2-1.jpg"
                  img2_url="photo/Michelin/2-2-2.jpg"
                />
                <Section
                  num="3"
                  name="시타마치텐동아키미츠"
                  li1="바삭하고 깔끔한 음식"
                  li2="가성비 대비 좋은 퀄리티"
                  li3="정말 깨끗한 청결"
                  star="⭐⭐⭐⭐"
                  n_href="https://naver.me/FWJPPIUr"
                  img1_url="photo/Michelin/2-3-1.jpg"
                  img2_url="photo/Michelin/2-3-2.jpg"
                />

                <Section
                  num="4"
                  name="동남집"
                  li1="가성비 맛집!!"
                  li2="곰탕에 국수사리까지 제공!!!"
                  li3="단점:다소 먼 거리"
                  star="⭐⭐⭐⭐"
                  n_href="https://naver.me/xtW55Kja"
                  img1_url="photo/Michelin/2/1.jpg"
                  img2_url="photo/Michelin/2/2.jpg"
                />
                <Section
                  num="5"
                  name="맛뜸"
                  li1="유명한 뼈해장국집"
                  li2="푸짐한 뼈와 시래기가 일품!"
                  li3="감자탕 또한 진국!!"
                  star="⭐⭐⭐⭐"
                  n_href="https://naver.me/501onXHm"
                  img1_url="photo/Michelin/2/5-1.jpg"
                  img2_url="photo/Michelin/2/5-2.jpg"
                />
                <Section
                  num="6"
                  name="수수커피"
                  li1="커피와 디저트가 맛잇는 집!"
                  li2="쑥 아이스크림 추천! 쌉싸래한 쑥맛이 포인트😋"
                  li3="디저트 맛집!!"
                  star="⭐⭐⭐⭐"
                  n_href="https://naver.me/GudviICa"
                  img1_url="photo/Michelin/2/6-1.jpg"
                  img2_url="photo/Michelin/2/6-2.jpg"
                />
                <div className="mt-2 mb-5">
                  <span>금호전기 할 수있다!! 점심드시고 화이팅🙋‍♂️🙋‍♀️!!!!!</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <CommentBox boardidx="0003" boardseq="2" />
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
}

export default Tasty2;
