import { useParams } from "react-router-dom";
import { setCookie } from "../utils/utilKumho.js";
import CheckLogin from "../utils/CheckLogin.js";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function LoginOk() {
  const param = useParams();
  const history = useHistory();

  console.log(param.id);
  console.log(param.empnm);
  if (param.id != "" && param.empnm != "" && param.empnm != null) {
    setCookie("KUMHO", `Empl_Num=${param.id}&Empl_Nme=${param.empnm}&`, 1);
    history.push("/");
  } else {
    alert("error!! code:001");
  }
  //로그인 기능.
  //외부에서 받은 Parameter를 기준으로 쿠키를 생성
  //1. Parameter를 받고 출력 console.log(param);
  //2. 받은 Param을 쿠키로 생성.
  //3. Main Goto
  // 로딩 빙글빙글 그림을 넣을 예정.
  return (
    <div
      style={{ height: "90vh " }}
      className="d-flex align-items-center justify-content-center"
    >
      <div style={{ top: "50%", left: "50%" }}>
        <Spinner
          style={{ width: "6rem", height: "6rem" }}
          animation="border"
          variant="danger"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <br />
        Loading...
      </div>
    </div>
  );
}

export default LoginOk;
