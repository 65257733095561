import NavBar from "./NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/CSS/carousel.css";
import "../assets/CSS/View.css";
import CommentBox from "./board/CommentBox.js";
import CheckLogin from "../utils/CheckLogin.js";

function View3() {
  return (
    <div>
      <CheckLogin />
      <header>
        <NavBar />
      </header>
      <main>
        <div
          id="myCarousel test"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className={`carousel-item active`}>
              <img
                src="photo/Soccer/20240703/3-9.jpg" //"photo/1.jpg"}
                width="100%"
                height="100%"
              />

              <div className="container">
                <div className="carousel-caption text-bg-light vw-ct-title">
                  <div className="vw-top text-start tit1">
                    <span className="ms-5">금호FC #축구#풋살#할수있다</span>
                  </div>
                  <div className="center">
                    <h1 className="vw-title">금호FC 축구회</h1>
                  </div>
                  <div className="vw-title-right text-center tit2">
                    2024.07.03
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators"></div>
        </div>
        <div className="container marketing">
          <div className="row">
            <div className="position-relative col-lg-12">
              <div className="position-absolute m-2">
                <div className="col-12">
                  <span>금호전기 금호FC 축구팀의 열기 감상하시죠~!</span>
                </div>
                <br />
                <div className="d-flex flex-wrap">
                  <img
                    src="photo/Soccer/20240703/1-1.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/1-2.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/1-3.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/1-4.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/1-5.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/1-6.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/1-7.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/1-8.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/1-9.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/1-10.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/2-1.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/2-2.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/2-3.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/2-4.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/2-5.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/3-1.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/3-2.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/3-3.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/3-4.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/3-5.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/3-6.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/3-7.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/3-8.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/3-9.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/9-1.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-11  img-fluid"
                  />
                  <img
                    src="photo/Soccer/20240703/9-2.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-11  img-fluid"
                  />
                </div>
                <div className="col-12">
                  <CommentBox boardidx="0004" boardseq="1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
}
export default View3;
