import { useState, useEffect } from "react";
import NavBar from "../NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/CSS/carousel.css";
import "../../assets/CSS/View.css";

import CommentBox from "./CommentBox.js";
import CheckLogin from "../../utils/CheckLogin.js";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../utils/utilKumho.js";
import DOMPurify from "dompurify";
import moment from "moment";

function BoardView({ AdminYn }) {
  const param = useParams();
  const [viewdata, setViewdata] = useState({
    Title: "",
    Sm_title: "",
    Content: "",
    WriteData: "",
    boardidx: "",
    boardseq: "",
    EndIdx: "",
    ImgURL: "",
  });

  async function getData(Viewtype) {
    const response = await axios.get(APIURL("getBoard"), {
      params: {
        type: Viewtype,
        boardidx: param.idx,
        boardseq: param.seq,
      },
    });

    const data = response.data[0];

    const JsonData = {
      Title: data.title,
      Sm_title: data.subtitle,
      WriteData: data.regdate,
      boardidx: data.board_idx,
      boardseq: data.board_seq,
      //EndIdx: data.max,
      PrevSeq: data.prev,
      NextSeq: data.next,
      FrontContent: data.content,
      BannerImgURL: data.Banner_img,
    };

    setViewdata(JsonData);
  }

  useEffect(() => {
    if (AdminYn === "Y") {
      getData("ADView");
    } else {
      getData("BDView");
    }
  }, [param]);

  const sanitizedHTML = DOMPurify.sanitize(viewdata.FrontContent, {
    ADD_TAGS: ["iframe", "figure"],
    ADD_ATTR: [
      "class",
      "style",
      "src",
      "width",
      "height",
      "alt",
      "title",
      "target",
    ],
    SAFE_FOR_TEMPLATES: true,
  });

  function Content() {
    return (
      <div>
        <div className="col-12">
          <span>
            <div
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: sanitizedHTML,
              }}
            />
          </span>
        </div>
        <br />
        <div className="mt-2 mb-5"></div>
      </div>
    );
  }

  function PageMoveButton() {
    return (
      <div>
        {viewdata.PrevSeq && (
          <Link
            className="btn btn-danger"
            to={`/BoardView/${viewdata.boardidx}/${viewdata.PrevSeq}`}
          >
            이전
          </Link>
        )}

        {viewdata.NextSeq && (
          <Link
            className="btn btn-success ms-3"
            to={`/BoardView/${viewdata.boardidx}/${viewdata.NextSeq}`}
          >
            다음
          </Link>
        )}
      </div>
    );
  }

  return (
    <div>
      <CheckLogin />
      <header>
        <NavBar />
      </header>
      {viewdata.boardidx !== "" ? (
        <main>
          <div
            id="myCarousel"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={`${viewdata.BannerImgURL}`}
                  width="100%"
                  height="100%"
                />
                <div className="container">
                  <div className="carousel-caption text-bg-light vw-ct-title">
                    <div className="vw-top text-start tit1">
                      <span className="ms-5">{viewdata.Sm_title}</span>
                    </div>
                    <div className="center">
                      <h1 className="vw-title">{viewdata.Title}</h1>
                    </div>
                    <div className="vw-title-right text-center tit2">
                      {moment.utc(viewdata.WriteData).format("YYYY.MM.DD")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container marketing">
            <div className="row text-center">
              {AdminYn === "Y" ? (
                ""
              ) : (
                <div className="col-12">
                  <PageMoveButton />
                </div>
              )}
            </div>
            <div className="row mt-4">
              <div className="col-lg-12">
                <Content />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <CommentBox
                  boardidx={viewdata.boardidx}
                  boardseq={viewdata.boardseq}
                />
              </div>
            </div>
          </div>
        </main>
      ) : (
        "NowLoading"
      )}
      <footer></footer>
    </div>
  );
}

export default BoardView;
