import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Navbar from "./NavBar.js";
import CheckLogin from "../utils/CheckLogin.js";
import Carousel from "../Layout/Carousel.js";
import Construction from "./Construction.js";

import { Link } from "react-router-dom";
import { List } from "./index.js";
function Main() {
  const ConstructionYn = false;

  return (
    <div>
      {ConstructionYn ? (
        <Construction />
      ) : (
        <div>
          <CheckLogin />
          <header>
            <Navbar />
          </header>
          <main>
            <Carousel />
          </main>
          <footer>
            <List mainYn="Y" />
          </footer>
        </div>
      )}
    </div>
  );
}

export default Main;
