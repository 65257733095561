import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  Main,
  View,
  View2,
  View3,
  View4,
  View5,
  TotalView,
  RelayToon,
  RelayToon2,
  RelayToon3,
  RelayToon4,
  RelayToon5,
  Tasty,
  Tasty2,
  List,
  Login,
  LoginOk,
  BoardWrite,
  BoardView,
  Admin,
  Loading,
} from "./routes/index";

import ScrollToTop from "./component/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/Loading">
          <Loading />
        </Route>
        <Route path="/Admin">
          <Admin />
        </Route>
        <Route path="/BoardView/:idx/:seq">
          <BoardView />
        </Route>
        <Route path="/BoardViewAD/:idx/:seq">
          <BoardView AdminYn="Y" />
        </Route>
        <Route path="/BoardWrite">
          <BoardWrite />
        </Route>
        <Route path="/TotalView/:idx/:seq">
          <TotalView />
        </Route>
        <Route path="/View5">
          <View5 />
        </Route>
        <Route path="/View4">
          <View4 />
        </Route>
        <Route path="/View3">
          <View3 />
        </Route>
        <Route path="/View2">
          <View2 />
        </Route>
        <Route path="/View">
          <View />
        </Route>
        <Route path="/RelayToon">
          <RelayToon />
        </Route>
        <Route path="/RelayToon2">
          <RelayToon2 />
        </Route>
        <Route path="/RelayToon3">
          <RelayToon3 />
        </Route>
        <Route path="/RelayToon4">
          <RelayToon4 />
        </Route>
        <Route path="/RelayToon5">
          <RelayToon5 />
        </Route>
        <Route path="/Tasty">
          <Tasty />
        </Route>
        <Route path="/Tasty2">
          <Tasty2 />
        </Route>
        <Route path="/List/:idx">
          <List />
        </Route>
        <Route path="/Login">
          <Login />
        </Route>
        <Route path="/LoginOk/:id/:empnm">
          <LoginOk />
        </Route>
        <Route path="/LoginOk2/:id/:empnm">
          <LoginOk />
        </Route>
        <Route path="/main">
          <Main />
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
