import NavBar from "../routes/NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/CSS/carousel.css";
import "../assets/CSS/View.css";
import CommentBox from "./board/CommentBox.js";
import CheckLogin from "../utils/CheckLogin.js";

function View() {
  return (
    <div>
      <CheckLogin />
      <header>
        <NavBar />
      </header>
      <main>
        <div
          id="myCarousel test"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className={`carousel-item active`}>
              <img
                src="photo/20240524Golf/IFC2.jpg" //"photo/1.jpg"}
                width="100%"
                height="100%"
              />

              <div className="container">
                <div className="carousel-caption text-bg-light vw-ct-title">
                  <div className="vw-top text-start tit1">
                    <span className="ms-5">골프대회 #세레니티CC</span>
                  </div>
                  <div className="center">
                    <h1 className="vw-title">
                      금호전기 골프대회! 성황리에 마치다!!
                    </h1>
                  </div>
                  <div className="vw-title-right text-center tit2">2024.05</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators"></div>
        </div>
        <div className="container marketing">
          <div className="row">
            <div className="position-relative  col-lg-12">
              <div className="position-absolute">
                <div className="col-12">
                  <span>
                    금호전기 창립 89주년 골프대회가 세레니티cc에서 개최되었다.
                  </span>
                </div>
                <br />

                <img
                  src="photo/20240524Golf/golf.jpg"
                  width="100%"
                  height="100%"
                  className="rounded"
                />
                <div className="col-12 m-3">
                  <br />
                  <span>
                    거래처와 임직원이 하나되어 대회를 성공적으로 마무리하였다.
                  </span>
                  <br />
                </div>
                <img
                  src="photo/20240524Golf/AllPeace.jpg"
                  width="100%"
                  height="100%"
                  className="rounded"
                />
                <div className="col-12 m-3">
                  <br />
                  <span>즐거워하는 직원들.</span>
                  <br />
                </div>
                <img
                  src="photo/20240524Golf/HappySeo.jpg"
                  width="100%"
                  height="100%"
                  className="rounded"
                />
                <div className="mt-5">
                  <span>
                    금호전기의 발전을 기원하며 90주년 대회가 기대되는바이다.
                  </span>
                </div>
                <div className="mt-5 mb-5 youtube">
                  <span>회사영상</span>
                  <iframe
                    width="100%"
                    height="700"
                    src="https://www.youtube.com/embed/onv72nBNe6Q?si=Aku09JlVUUvMfmxD"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                  <a
                    href="https://drive.google.com/drive/folders/1BA3iZeAKaofRW6nVMHc9AfO7k8u_muy8?usp=drive_link"
                    className="btn btn-success m-4 col-5"
                  >
                    📷 골프대회 사진
                  </a>
                </div>
                <div className="col-12">
                  <CommentBox boardidx="0001" boardseq="1" />
                </div>
              </div>
              <div className="position-absolute m-2 col-10 bg-light"></div>
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
}
export default View;
