// src/utils/utilKumho.js
import axios from "axios";
import { useState, useEffect } from "react";

//-----------------------------------------쿠키 함수-------------------------------------------

export function useCookie() {
  const [empInfo, setEmpInfo] = useState([]);

  function getData() {
    const value = document.cookie.match("(^|;) ?" + "KUMHO" + "=([^;]*)(;|$)");
    if (value !== null) {
      const decordvalue = decodeURIComponent(value[2]);
      const cksplit = decordvalue.split("&");
      const Emp_no = cksplit[0].split("=")[1];
      const emp_nm = cksplit[1].split("=")[1];
      setEmpInfo([Emp_no, emp_nm]);
    } else {
      setEmpInfo(["0", "empty"]);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return empInfo;
}
export function useCookie2() {
  const [empInfo, setEmpInfo] = useState({ EmpNo: "", EmpNm: "" });

  function getData() {
    const value = document.cookie.match("(^|;) ?" + "KUMHO" + "=([^;]*)(;|$)");
    if (value != null) {
      const decordvalue = decodeURIComponent(value[2]);
      const cksplit = decordvalue.split("&");
      const Emp_no = cksplit[0].split("=")[1];
      const emp_nm = cksplit[1].split("=")[1];
      setEmpInfo({ EmpNo: Emp_no, EmpNm: emp_nm });
    } else {
      setEmpInfo({ EmpNo: "0", EmpNm: "empty" });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return empInfo;
}

export function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  const encordvalue = encodeURIComponent(value);
  document.cookie = name + "=" + encordvalue + ";" + expires + ";path=/";
}

//-----------------------------파일 업로드 ----------------------------------

export const customUploadAdapter = (loader) => {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const data = new FormData();
        loader.file.then((file) => {
          //console.log(file);
          data.append("file", file);

          axios
            .post("https://api.raycure.co.kr/img/upload", data)
            .then((response) => {
              if (response.data.success) {
                resolve({
                  default: response.data.url,
                });
              } else {
                reject("Upload failed");
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
    },
  };
};

//-------------------------------API URL-----------------------------
export function APIURL(type) {
  var test = "";
  //var test = "Y";

  if (test !== "Y") {
    switch (type) {
      case "getCmm":
        return `https://api.raycure.co.kr/SaleDayData/getCmmData/`;
        break;
      case "setCmm":
        return `https://api.raycure.co.kr/SaleDayData/setCmmData/`;
        break;
      case "delCmm":
        return `https://api.raycure.co.kr/SaleDayData/delCmmData/`;
        break;
      case "PostBoard":
        return `https://api.raycure.co.kr/SaleDayData/callSpboardWrite/`;
        break;
      case "PostBoard2":
        return `https://api.raycure.co.kr/SaleDayData/callSpboardWrite2/`;
        break;
      case "getBoard":
        return `https://api.raycure.co.kr/SaleDayData/callSpboardComm/`;
        break;
      case "PostFileData":
        return `https://api.raycure.co.kr/SaleDayData/callSpBoardFileSave/`;
        break;
      case "PostInfo":
        return `https://api.raycure.co.kr/SaleDayData/login/`;
        break;
    }
  } else {
    switch (type) {
      case "getCmm":
        return `http://api.raycure.co.kr:3838/SaleDayData/test/getCmmData/`;
        break;
      case "setCmm":
        return `http://api.raycure.co.kr:3838/SaleDayData/test/setCmmData/`;
        break;
      case "delCmm":
        return `http://api.raycure.co.kr:3838/SaleDayData/test/delCmmData/`;
        break;
      case "PostBoard":
        return `http://api.raycure.co.kr:3838/SaleDayData/test/callSpboardWrite/`;
        break;
      case "PostBoard2":
        return `http://api.raycure.co.kr:3838/SaleDayData/test/callSpboardWrite2/`;
        break;
      case "getBoard":
        return `http://api.raycure.co.kr:3838/SaleDayData/test/callSpboardComm/`;
        break;
      case "PostFileData":
        return `http://api.raycure.co.kr:3838/SaleDayData/test/callSpBoardFileSave/`;
        break;
      case "PostInfo":
        return `http://api.raycure.co.kr:3838/SaleDayData/login/`;
        break;
    }
  }
}
