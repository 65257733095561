import NavBar from "./NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/CSS/carousel.css";
import "../assets/CSS/View.css";
import CommentBox from "./board/CommentBox.js";
import CheckLogin from "../utils/CheckLogin.js";

function View3() {
  return (
    <div>
      <CheckLogin />
      <header>
        <NavBar />
      </header>
      <main>
        <div
          id="myCarousel test"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className={`carousel-item active`}>
              <img
                src="photo/20240524Golf/IFC2.jpg" //"photo/1.jpg"}
                width="100%"
                height="100%"
              />

              <div className="container">
                <div className="carousel-caption text-bg-light vw-ct-title">
                  <div className="vw-top text-start tit1">
                    <span className="ms-5">포상행사 #장기근속사원</span>
                  </div>
                  <div className="center">
                    <h1 className="vw-title">
                      창립 89주년 장기근속사원 포상 행사
                    </h1>
                  </div>
                  <div className="vw-title-right text-center tit2">2024.05</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators"></div>
        </div>
        <div className="container marketing">
          <div className="row">
            <div className="position-relative col-lg-12">
              <div className="position-absolute m-2">
                <div className="col-12">
                  <span>금호전기 창립 89주년 장기근속 사원 축하 행사 사진</span>
                </div>
                <br />

                <img
                  src="photo/Company/Ddabong.jpg"
                  width="100%"
                  height="100%"
                  className="rounded"
                />

                <div className="col-12 mt-5 mb-5 youtube">
                  <span>회사 창립89주년 장기근속 사원 시상 및 축하 영상</span>
                  <iframe
                    width="100%"
                    height="700"
                    src="https://www.youtube.com/embed/K_rbtvWbkkA?si=MbLaLeJyq8y-syVr"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="col-12">
                  <CommentBox boardidx="0001" boardseq="2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
}
export default View3;
