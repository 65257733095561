import { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useHistory } from "react-router-dom";
import { setCookie } from "../utils/utilKumho.js";
import { APIURL } from "../utils/utilKumho.js";
function Login() {
  const [employeeId, setEmployeeId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(APIURL("PostInfo"), {
        Empl_Num: employeeId,
        memPass: password,
      });
      // Handle success response
      //console.log(response);
      if (response.status === 200) {
        //console.log("이프문동작");
        await getUserdata(employeeId);
        // 데이터 불러오고 쿠키 만들고 링크가 넘어가기!

        history.push("/");
      }

      // Redirect or perform further actions
    } catch (error) {
      // Handle error response
      console.error(error);
      setError("로그인 실패. 다시 시도해 주세요.");
    }
  };

  const getUserdata = async (EmpNo) => {
    const response = await axios.get(
      `https://api.raycure.co.kr/SaleDayData/getUser/${EmpNo}?format=json`
    );

    //console.log(response.data);
    const Empl_Num = response.data.Empl_Num.trim();
    const Empl_Nme = response.data.Empl_Nme;
    console.log("DB에서 데이터 불러오는구간");
    console.log(Empl_Nme);
    console.log("구간끝");
    setCookie("KUMHO", `Empl_Num=${Empl_Num}&Empl_Nme=${Empl_Nme}&`, 1); // 쿠키 설정
  };

  return (
    <div className="container col-lg-4 col-md-6 col-sm-8 mx-auto mt-5">
      <div className="card shadow-lg">
        <div className="card-body">
          <h3 className="card-title text-center">로그인</h3>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="employeeId" className="form-label">
                사원번호
              </label>
              <input
                type="text"
                className="form-control"
                id="employeeId"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                비밀번호
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-danger w-100">
              로그인
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
