import { useState, useEffect } from "react";
import Sgv from "../assets/Sgv";
function ThemeMode() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  const handleTheme = (newTheme) => {
    setTheme(newTheme);
    document.documentElement.setAttribute("data-bs-theme", newTheme);
    localStorage.setItem("theme", newTheme);
  };

  //console.log(localStorage.getItem("theme"));
  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", theme);
    //type을 지정.
  }, [theme]);
  //1. 버튼을 클릭 bstheme color  뭐 그걸 설정.
  //2. document.setAttribute();
  return (
    <div
      className="btn-group"
      role="group"
      style={{ position: "absolute", top: "50%", left: "50%" }}
    >
      <div className="dropdown position-fixed bottom-0 end-0 mb-3 me-3 bd-mode-toggle">
        <button
          class="btn btn-bd-primary py-2 dropdown-toggle d-flex align-items-center"
          id="bd-theme"
          type="button"
          aria-expanded="false"
          data-bs-toggle="dropdown"
          aria-label="Toggle theme (auto)"
        >
          <Sgv type={theme} />
        </button>
        <ul className="dropdown-menu dropdown-menu-end shadow">
          <li>
            <button
              type="button"
              className="dropdown-item d-flex align-items-center"
              data-bs-theme-value="light"
              aria-pressed="false"
              onClick={() => handleTheme("light")}
            >
              <Sgv type="light" />
            </button>
          </li>
          <li>
            <button
              type="button"
              className="dropdown-item d-flex align-items-center"
              data-bs-theme-value="light"
              aria-pressed="false"
              onClick={() => handleTheme("dark")}
            >
              <Sgv type="dark" />
            </button>
          </li>
          <li>
            <button
              type="button"
              className="dropdown-item d-flex align-items-center"
              data-bs-theme-value="light"
              aria-pressed="false"
              onClick={() => handleTheme("auto")}
            >
              <Sgv type="auto" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ThemeMode;
