import NavBar from "./NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/CSS/carousel.css";
import "../assets/CSS/View.css";
import CommentBox from "./board/CommentBox.js";
import CheckLogin from "../utils/CheckLogin.js";
import { Link } from "react-router-dom";

function Tasty() {
  return (
    <div>
      <CheckLogin />
      <header>
        <NavBar />
      </header>
      <main>
        <div
          id="myCarousel test"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="photo/Michelin/Michelin.jpg"
                width="100%"
                height="100%"
                className="photocut"
              />
              <div className="container">
                <div className="carousel-caption text-bg-light vw-ct-title">
                  <div className="vw-top text-start tit1">
                    <span className="ms-5">맛집 추천 #맛집#금쉐린</span>
                  </div>
                  <div className="center">
                    <h1 className="vw-title">
                      금호전기⚡ 여의도 점심 맛집 추천 리스트😋👨‍🍳😋!!!!
                    </h1>
                  </div>
                  <div className="vw-title-right text-center tit2">2024.07</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators"></div>
        </div>
        <div className="container marketing">
          <div className="row mt-4">
            <div className="col-lg-12">
              <div>
                <div className="col-12">
                  <span>
                    늘 고르기 힘든 점심메뉴... 여러분께 추천드립니다. 여의도
                    점심 맛집 추천 리스트...! 감상하시죠!! 👨‍🍳
                    <br />
                  </span>
                </div>
                <br />
                <div className="col-12 mb-3">
                  <span>
                    <strong>1) 청식당 - 미나리 부대찌개</strong>
                    <ul>
                      <li>
                        특이하게 부대찌개 위에 푸짐히 올려주는 미나리가 압권
                      </li>
                      <li>
                        계란말이를 시키면 특대사이즈로 나와서 배부르게 한끼
                        가능!
                      </li>
                      <li>미나리를 찍어먹는 와사비소스의 절묘함.</li>
                    </ul>
                    ⭐⭐⭐⭐
                    <br />
                  </span>
                  <a
                    className="btn btn-success"
                    target="_blank"
                    href="https://naver.me/FVPBXzC9"
                  >
                    청식당 네이버지도 보기
                  </a>
                </div>
                <div className="d-flex flex-wrap">
                  <img
                    src="photo/Michelin/1.jpeg"
                    className="rounded border col-12 col-md-5 me-3 img-fluid"
                  />
                  <img
                    src="photo/Michelin/2.jpeg"
                    className="rounded border col-12 col-md-5 img-fluid"
                  />
                </div>
                <div className="col-12 mt-5 mb-3">
                  <span>
                    <strong>2) 한미옥 - 솥밥정식</strong>
                    <ul>
                      <li>고급진 분위기와 고급진 음식</li>
                      <li>
                        점심 특선으로 솥밥정식류 판매 솥밥-차돌볶음-된장찌개
                        제공
                      </li>
                      <li>다소 높은 가격대 1만6천~2만원</li>
                    </ul>
                    ⭐⭐⭐⭐
                    <br />
                  </span>
                  <a
                    className="btn btn-success"
                    target="_blank"
                    href="https://naver.me/5MDtMCzb"
                  >
                    한미옥 네이버지도 보기
                  </a>
                </div>
                <div className="d-flex flex-wrap">
                  <img
                    src="photo/Michelin/2-1.jpg"
                    className="rounded border mt-3 me-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Michelin/2-2.jpg"
                    className="rounded border mt-3 me-3 col-12 col-md-5 img-fluid"
                  />
                  <img
                    src="photo/Michelin/2-3.jpg"
                    className="rounded border mt-3 me-3 col-12 col-md-5 img-fluid"
                  />
                  <img
                    src="photo/Michelin/2-4.jpg"
                    className="rounded border mt-3 me-3 col-12 col-md-5 img-fluid"
                  />
                </div>
                <div className="col-12 mt-5 mb-3">
                  <span>
                    <strong>3) 오늘은즉떡 - 즉석떡볶이</strong>
                    <ul>
                      <li>저렴한 가격대와 다 먹고 볶음밥까지 먹으면 환상</li>
                      <li>
                        웨이팅이 있으나 정말 빠르게 줄어들고 미리 주문을 받음.
                      </li>
                      <li>추가 할 수 있는 다양한 메뉴</li>
                    </ul>
                    ⭐⭐⭐
                    <br />
                  </span>
                  <a
                    className="btn btn-success"
                    target="_blank"
                    href="https://naver.me/G4rLNOSS"
                  >
                    오늘은즉떡 네이버지도 보기
                  </a>
                </div>
                <div className="d-flex flex-wrap">
                  <img
                    src="photo/Michelin/3-1.jpg"
                    className="rounded border me-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Michelin/3-2.jpg"
                    className="rounded border me-3 col-12 col-md-5  img-fluid"
                  />
                </div>

                <div className="col-12 mt-5 mb-3">
                  <span>
                    <strong>4) 진진만두 - 만두국</strong>
                    <ul>
                      <li>정갈한 밑반찬과 실하고 깔끔한 수제만두</li>
                      <li>술국은 특히 매큼하고 진하여 강추</li>
                      <li>단점: 높은 금액 1만6천~1만7천원</li>
                    </ul>
                    ⭐⭐⭐⭐
                    <br />
                  </span>
                  <a
                    className="btn btn-success"
                    target="_blank"
                    href="https://naver.me/F4N2gQhN"
                  >
                    진진만두 네이버지도 보기
                  </a>
                </div>
                <div className="d-flex flex-wrap">
                  <img
                    src="photo/Michelin/5-1.jpg"
                    className="rounded border me-3 col-12  col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Michelin/5-2.jpg"
                    className="rounded border me-3  col-12 col-md-5  img-fluid"
                  />
                </div>

                <div className="col-12 mt-5 mb-3">
                  <span>
                    <strong>5) 다솔식당 - 짜글이,직화구이 제육</strong>
                    <ul>
                      <li>짜글이,제육등 불맛이 살아있는 음식</li>
                      <li>깔끔하고 가성비 좋은 다양한 메뉴</li>
                      <li>
                        단점: 돈대리 근처에 있는데 찾기가 힘듬(구석에 위치)
                      </li>
                    </ul>
                    ⭐⭐⭐⭐
                    <br />
                  </span>
                  <a
                    className="btn btn-success"
                    target="_blank"
                    href="https://naver.me/5oX9lYFS"
                  >
                    다솔식당 네이버지도 보기
                  </a>
                </div>
                <div className="d-flex flex-wrap">
                  <img
                    src="photo/Michelin/6-1.jpg"
                    width="45%"
                    height="500px"
                    className="rounded border me-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Michelin/6-2.jpg"
                    width="45%"
                    height="500px"
                    className="rounded border me-3 col-12 col-md-5  img-fluid"
                  />
                </div>
                <div className="col-12 mt-5 mb-3">
                  <span>
                    <strong>6) 더현대 테디뵈르하우스 - 크로와상,빵</strong>
                    <ul>
                      <li>
                        크로와상 엄청 바삭,담백하며 버터풍미가 진하게 느껴짐.
                      </li>
                      <li>인테리어 맛집. 빵 다양한 메뉴</li>
                      <li>🧸위치 더현대 지하1층</li>
                    </ul>
                    ⭐⭐⭐⭐
                    <br />
                  </span>
                  <a
                    className="btn btn-success"
                    target="_blank"
                    href="https://naver.me/GzgQ1ydQ"
                  >
                    테디뵈르하우스 네이버지도 보기
                  </a>
                </div>
                <div className="d-flex flex-wrap">
                  <img
                    src="photo/Michelin/7-1.jpg"
                    width="45%"
                    height="500px"
                    className="rounded border me-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Michelin/7-2.jpg"
                    width="45%"
                    height="500px"
                    className="rounded border me-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Michelin/7-3.jpg"
                    width="45%"
                    height="500px"
                    className="rounded border mt-3 me-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/Michelin/7-4.jpg"
                    width="45%"
                    height="500px"
                    className="rounded border mt-3 me-3 col-12 col-md-5  img-fluid"
                  />
                </div>
                <div className="mt-2 mb-5">
                  <span>맛있는 점심드시고 화이팅🙋‍♂️🙋‍♀️!!!!!</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <CommentBox boardidx="0003" boardseq="1" />
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
}

export default Tasty;
