import axios from "axios";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { APIURL } from "../utils/utilKumho";
import "../assets/CSS/carousel.css";
import Loading from "../Layout/Loading";
function Carousel() {
  const [carouData, setCarouData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getData() {
    try {
      const response = await axios.get(APIURL("getBoard"), {
        params: { type: "BDtop4", boardidx: "", boardseq: "" },
      });

      setCarouData(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getData();
  }, []);
  function CarouItem({
    imgsrc,
    act,
    title,
    content,
    btntitle,
    position,
    linkto,
    able,
  }) {
    //console.log(imgsrc);
    return (
      <div className={`carousel-item ${act}`}>
        <img
          src={process.env.PUBLIC_URL + imgsrc} //"photo/1.jpg"}
          width="100%"
          height="100%"
          classNameName="photocut"
        />

        <div className="container">
          <div className={`carousel-caption ${position}`}>
            <h1>
              <strong>{title}</strong>
            </h1>
            <p>{content}</p>
            <p>
              <Link
                className={`btn btn-lg btn-danger ${able}`}
                to={`${linkto}`}
              >
                {btntitle}
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id="myCarousel"
      className="carousel slide pb-0 mb-0"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
      </div>
      <div className="carousel-inner">
        {loading ? (
          <div className={`carousel-item active`}>
            <div
              style={{
                position: "absolute",
                minWidth: "100%",
                top: "0",
                left: "0",
                height: "44rem",
              }}
            >
              <Loading type="main" />
            </div>
          </div>
        ) : carouData.length !== 0 ? (
          carouData.map((ca, idx) => (
            <CarouItem
              imgsrc={ca.Banner_img}
              act={idx === 0 ? "active" : ""}
              title={ca.title}
              content={ca.subtitle}
              btntitle="보러가기"
              position=""
              linkto={`/BoardView/${ca.board_idx}/${ca.board_seq}`}
            />
          ))
        ) : (
          <div></div>
        )}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#myCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}

export default Carousel;
