import { useState, useEffect, useRef } from "react";
import moment from "moment";
import axios from "axios";
import CmmView from "./CmmView.js";
import { useCookie, APIURL } from "../../utils/utilKumho.js";
import { Overlay, Popover, Button } from "react-bootstrap";
import EmojiPicker from "emoji-picker-react";
import { ReactComponent as Smile } from "../../assets/smile.svg";

function CommentBox({ boardidx, boardseq }) {
  const [Comment, setComment] = useState("");
  const [Cmtwriter, setCmtwriter] = useState("");
  const [CmtwriterNo, setCmtwriterNo] = useState("");
  const [refT, setRefT] = useState(0);
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const Empnm = useCookie();

  const setEmoji = (event) => {
    const emoji = event.emoji;
    setComment((preComment) => preComment + emoji);
  };

  const onChange = (e) => {
    setComment(e.target.value);
    setShow(false);
  };

  const onActiveEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onSubmit();
    }
  };

  const onSubmit = async (event) => {
    if (Comment === "") {
      alert("리플을 적으세요");
      return;
    }
    await postData();
    setComment("");
    setRefT((prevRefT) => prevRefT + 1);
  };

  const onClick = (event) => {
    setShow(false);
    onSubmit();
  };

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  async function postData() {
    try {
      const nowTime = moment().format("YYYY-MM-DD HH:mm:ss");

      const response = await axios.post(
        //"http://api.raycure.co.kr:3838/SaleDayData/setCmmData/",
        APIURL("setCmm"),
        {
          board_idx: `${boardidx}`,
          board_seq: `${boardseq}`,
          comment: `${Comment}`,
          regdate: `${nowTime}`,
          cmtWriter: `${Cmtwriter}`,
          cmtWriterNo: `${CmtwriterNo}`,
        }
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    setCmtwriter(Empnm[1]);
    setCmtwriterNo([Empnm[0]]);
  }, [Empnm]);

  // Popover 외부 클릭 감지

  return (
    <div className="container my-4" ref={ref}>
      <h4 className="pb-2 mb-4 border-bottom">댓글</h4>
      <div className="row">
        <div className="col-12">
          <CmmView board_idx={boardidx} board_seq={boardseq} refT={refT} />
        </div>
      </div>
      <form onSubmit={onSubmit} className="row g-3">
        <div className="col-md-2 d-flex align-items-center mt-4">
          <label htmlFor="writer" className="form-label"></label>
          <input
            type="text"
            className="form-control"
            id="writer"
            value={Cmtwriter}
            placeholder=""
            disabled
          />
        </div>
        <div className="col-md-8 d-flex align-items-center mt-4">
          <input
            type="text"
            className="form-control"
            id="comment"
            value={Comment}
            onChange={onChange}
            onKeyDown={onActiveEnter}
            placeholder="댓글을 입력해주세요."
          />
          <div className="ms-3">
            <Smile style={{ cursor: "pointer" }} onClick={handleClick} />
          </div>
        </div>
        <div className="col-md-2 d-flex align-items-center mt-4">
          <button
            type="button"
            onClick={onClick}
            className="btn btn-danger w-100"
          >
            댓글등록
          </button>
        </div>
      </form>
      <Overlay
        show={show}
        target={target}
        placement="top"
        container={ref.current}
        containerPadding={5}
      >
        <Popover id="popover-contained">
          <Popover.Body className="p-0">
            <EmojiPicker
              onEmojiClick={setEmoji}
              emojiStyle="native"
              emojiVersion="5.0"
              width="100"
              searchDisabled={true}
              autoFocusSearch={false}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

export default CommentBox;
