import CkEditor5 from "../editor/CkEditor5";
import { APIURL, useCookie2 } from "../../utils/utilKumho";
import BoardSelect from "./BoardSelect.js";
import axios from "axios";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Sgv from "../../assets/Sgv.js";
import moment from "moment";

import { useState, useEffect } from "react";

function BoardWrite({ boardidx, boardseq, childfunc, modify }) {
  const [contentData, setContentData] = useState("");
  const [Loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [board_idx, setBoardidx] = useState("");
  const [board_seq, setBoardseq] = useState("");
  const [reservdate, setReservDate] = useState();

  const [ThumbFile, setThumbFile] = useState("");
  const [Mainfile, setMainFile] = useState("");
  const [fileSaveName, setFileSaveName] = useState({
    Thumb_SaveName: "",
    Banner_SavenName: "",
  });
  const [fileOrgName, setFileOrgName] = useState([]);

  const [multiFile, setmultiFile] = useState([]);
  const [alarm, onAlarm] = useState(false);

  const EmpInfo = useCookie2();

  //--------------------글 수정 관련------------------------//
  useEffect(() => {
    if (modify === "Y") {
      getBoardData();
      setBoardidx(boardidx);
    }
  }, []);

  const CheckLogin = () => {
    const chk = document.getElementById("chkReserv");
    chk.checked = true;
    onClick();
  };

  async function getBoardData() {
    try {
      const response = await axios.get(APIURL("getBoard"), {
        params: { type: "Modify", boardidx: boardidx, boardseq: boardseq },
      });
      setTitle(response.data[0].title);
      setSubtitle(response.data[0].subtitle);
      setContentData(response.data[0].content);
      setReservDate(response.data[0].ReserveDate);
      if (response.data[0].ReserveDate) CheckLogin();
    } catch (error) {
      console.error(error);
    } finally {
      //console.log("수정 페이지 자료 불러오기 완료!");
    }
  }

  //수정으로 저장하기 만들기
  async function postData2(temp) {
    try {
      const response = await axios.post(APIURL("PostBoard2"), {
        type: `UPDATE`,
        boardidx: `${boardidx}`,
        boardseq: `${boardseq}`,
        title: `${title}`,
        subtitle: `${subtitle}`,
        content: `${contentData}`,
        writer: `${EmpInfo.EmpNo}`, //`${Boardwriter}`,
        thumbnail: `${
          fileSaveName.Thumb_SaveName ? fileSaveName.Thumb_SaveName : ""
        }`,
        banner: `${
          fileSaveName.Banner_SavenName ? fileSaveName.Banner_SavenName : ""
        }`,
        reservdate: alarm ? `${reservdate}` : null,
        tempYn: `${temp}`,
      });
      console.log(response);
      if (response.status == "201") {
        //setBoardidx(response.data.board_idx);
        setBoardseq(response.data.board_seq);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  //-----------------수정관련 끝----------------//
  useEffect(() => {
    //3. 파일 db업로드
    //썸네일이 있는경우
    if (fileSaveName.Thumb_SaveName != "" && board_seq) {
      console.log("썸네일 저장(db)");
      postFileData(fileSaveName.Thumb_SaveName, ThumbFile.name, "TN");
    }
    //배너가 있는경우
    if (fileSaveName.Banner_SavenName != "" && board_seq) {
      console.log("배너 저장(db)");
      postFileData(fileSaveName.Banner_SavenName, Mainfile.name, "BN");
    }

    //for (var i = 0; i < multiFile.length; i++) {
    //배열 길이만큼 반복
    //postFileData(i);  //본문에 저장해서 필요가 없어짐... 게시판에 다중 파일 등록이 필요하면 사용.
    //}
    if (board_seq) {
      alert("등록완료");
      ModalsClose();
    }
  }, [fileSaveName, board_seq]);

  const handleClick = async (e, temp) => {
    if (!board_idx) {
      alert("게시판 종류를 선택해주세요!!!");
    } else if (!title) {
      alert("제목을 입력해주세요!");
    } else if (!subtitle) {
      alert("부제목 입력해주세요!");
    } else {
      setLoading(true);

      //---------------------------insert-----------------------//
      if (modify !== "Y") {
        //1. 파일->서버 업로드
        if (ThumbFile || Mainfile) {
          await upload(e);
        }
        //2. 글 db업로드
        await postData(temp);
      } else {
        //파일을 삭제하고 업로드하게 만들고 싶은데...
        //1. 파일을 개별로 볼수 있게 단순치 띄운다.
        //2. 파일이 개별로 올라와 있을 경우 fileupload 비활성화
        //3. 파일 개별로 올라온것을 삭제하거나 파일이 없는 경우 활성화...?
        //우선 아래와 같이 진행해서 그냥 쓰고 그 다음 확인?
        if (ThumbFile || Mainfile) {
          await upload(e);
        }
        await postData2(temp);
      }

      //---------------------------update-----------------------//
    }
  };

  async function upload(e) {
    //다중 송신... append에 추가.
    //아 에... 이게... 걍 post를 파일 개수만큼 해야할거같은데 이건...
    // 따로 판단이 안됨 이름표를 붙여주고 보낼 수 있나?
    try {
      e.preventDefault();
      if (e) {
        const formData = new FormData();
        if (ThumbFile) {
          formData.delete("files");
          formData.append("files", ThumbFile);
          const Thumbresp = await axios.post(
            "https://api.raycure.co.kr/img/uploadmulti",
            formData
          );
          setFileSaveName((prev) => ({
            Banner_SavenName: prev.Banner_SavenName,
            Thumb_SaveName: `${Thumbresp.data.url}`,
          }));
        }
        if (Mainfile) {
          formData.delete("files");
          formData.append("files", Mainfile);

          const Bannerresp = await axios.post(
            "https://api.raycure.co.kr/img/uploadmulti",
            formData
          );
          setFileSaveName((prev) => ({
            Banner_SavenName: `${Bannerresp.data.url}`,
            Thumb_SaveName: prev.Thumb_SaveName,
          }));
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      console.log("file upload finally");
    }
  }

  async function postData(temp, modif) {
    try {
      const response = await axios.post(APIURL("PostBoard2"), {
        type: `WRITE`,
        boardidx: `${board_idx}`, //`${boardidx}`,
        boardseq: `1`, //`${boardseq}`,
        title: `${title}`,
        subtitle: `${subtitle}`,
        content: `${contentData}`,
        writer: `${EmpInfo.EmpNo}`, //`${Boardwriter}`,
        thumbnail: `${
          fileSaveName.Thumb_SaveName ? fileSaveName.Thumb_SaveName : ""
        }`,
        banner: `${
          fileSaveName.Banner_SavenName ? fileSaveName.Banner_SavenName : ""
        }`,
        reservdate: alarm ? `${reservdate}` : null,
        tempYn: `${temp}`,
      });
      console.log(response);
      if (response.status == "201") {
        //setBoardidx(response.data.board_idx);
        setBoardseq(response.data.board_seq);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function postFileData(orgname, savename, filetype) {
    try {
      const response = await axios.post(APIURL("PostFileData"), {
        type: `SAVE`,
        boardidx: `${board_idx}`,
        boardseq: `${board_seq}`,
        filetype: `${filetype}`,
        orgname: `${orgname}`,
        savename: `${savename}`,
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      console.log("finally filedatasave");
    }
  }

  async function postMultiFileData(i) {
    try {
      const response = await axios.post(APIURL("PostFileData"), {
        type: `SAVE`,
        boardidx: `${board_idx}`,
        boardseq: `${board_seq}`,
        orgname: `${fileOrgName[i]}`,
        savename: `${fileSaveName[i]}`,
      });
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      console.log("finally filedatasave");
    }
  }

  function onChange(type) {
    const id = type.target.id;
    const value = type.target.value;

    if (id == "title") {
      setTitle(value);
    } else if (id == "subtitle") {
      setSubtitle(value);
    } else if (id == "selBoard") {
      setBoardidx(value);
    } else if (id == "alarm") {
      setReservDate(value);
    }
  }

  function upload2(e) {
    //Input file multiple  인풋 다중파일시 사용 post
    e.preventDefault();
    //console.log(multiFile);
    if (e) {
      const data = new FormData();
      for (let i = 0; i < multiFile.length; i++) {
        data.append("files", multiFile[i]);
      }
      const resp = axios.post(
        "https://api.raycure.co.kr/img/uploadmulti",
        data
      );
      resp.then((res) => {
        //다중 인풋 (<input type="file" multiple/>의 tag를 사용할 경우 1. multiFile state에 set 2.upload2 실행하면 다중 파일 전송 3. json값으로 originalname내용 배열로 return.)
      });
    }
  }

  const handleChange = (e) => {
    const id = e.target.id;
    //console.log(id);
    console.log(e.target.files);

    if (id == "formThumbnailFile") {
      setThumbFile(e.target.files[0]);
      //setFileOrgName((prev) => [...prev, e.target.files[0].name]); //org가 필요하면 그 org을 불러오고 셋해야함
    } else if (id == "formMainFile") {
      setMainFile(e.target.files[0]);
      //setFileOrgName((prev) => [...prev, e.target.files[0].name]);
    } else if (id == "multifile") {
      setmultiFile(e.target.files);
    }
  };

  const ModalsClose = () => {
    if (childfunc) childfunc();
  };

  const onClick = (e) => {
    const infotoggle = document.getElementById("info").style;
    const alarmActive = document.getElementById("alarm");

    if (infotoggle.display == "none") {
      //체크가 됬을 시
      infotoggle.display = "";
      alarmActive.disabled = false;
      onAlarm(true);
    } else {
      infotoggle.display = "none";
      alarmActive.disabled = true;
      onAlarm(false);
    }
  };

  return (
    <div className="container">
      <div className="col-12 d-flex">
        <BoardSelect
          onChange={onChange}
          className={`col-4`}
          boardidx={boardidx}
          disabled={modify}
        />
      </div>
      <div className="col-12">
        <input
          type="text"
          id="title"
          name="title"
          placeholder="제목을 입력해주세요."
          className="form-control mt-3"
          value={title}
          onChange={onChange}
        />
        <input
          type="text"
          id="subtitle"
          name="subtitle"
          placeholder="부제목을 입력해주세요. 예)#해쉬테그"
          className="form-control mt-3"
          value={subtitle}
          onChange={onChange}
        />
      </div>
      <div className="mt-3">
        <CkEditor5
          onChange={(data) => setContentData(data)}
          content={contentData}
        />

        {Loading && <div>loading....</div>}
      </div>
      <div className="mt-4 text-center">
        <Form.Group controlId="formThumbnailFile" className="mb-3">
          <Form.Label>썸네일 사진</Form.Label>
          <Form.Control type="file" size="sm" onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId="formMainFile" className="mb-3">
          <Form.Label>글상단 메인 사진</Form.Label>
          <Form.Control type="file" size="sm" onChange={handleChange} />
        </Form.Group>
      </div>

      <div className="row t-2">
        <div className="col-6 text-end oe-0">
          <button
            type="button"
            className="btn btn-outline-secondary m-1"
            onClick={(e) => handleClick(e, "Y")}
          >
            <Sgv type="upload" />
            임시
          </button>
        </div>
        <div className="col-auto p-0">
          <button
            type="button"
            className="btn btn-success m-1"
            onClick={(e) => handleClick(e, "N")}
          >
            <Sgv type="docu" />
            발행
          </button>
        </div>

        <div className="col-auto">
          <div className="input-group input-group-sm mt-2">
            <div className="input-group-text">
              <input
                id="chkReserv"
                className="form-check-input mt-0"
                type="checkbox"
                value=""
                aria-label="Checkbox for following text input"
                onClick={onClick}
              />
            </div>
            <span
              className="input-group-text bg-secondary text-white"
              id="inputGroup-sizing-sm"
            >
              <Sgv type="alarm" />
              발행 예약
            </span>
            <input
              type="date"
              id="alarm"
              name="alarm"
              value={reservdate && moment.utc(reservdate).format("YYYY-MM-DD")}
              className="form-control"
              onChange={onChange}
              disabled
            />
            <div
              className="input-group-text bg-secondary text-white"
              id="info"
              style={{ display: "none" }}
            >
              지정날짜부터 표기됩니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoardWrite;
