import NavBar from "./NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/CSS/carousel.css";
import "../assets/CSS/View.css";
import CommentBox from "./board/CommentBox.js";
import CheckLogin from "../utils/CheckLogin.js";

function View5() {
  return (
    <div>
      <CheckLogin />
      <header>
        <NavBar />
      </header>
      <main>
        <div
          id="myCarousel test"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className={`carousel-item active`}>
              <img
                src="photo/SmartMesh/1.jpg" //"photo/1.jpg"}
                width="100%"
                height="100%"
              />

              <div className="container">
                <div className="carousel-caption text-bg-light vw-ct-title">
                  <div className="vw-top text-start tit1">
                    <span className="ms-5">#신제품 #Iot #스마트조명</span>
                  </div>
                  <div className="center">
                    <h1 className="vw-title">금호전기 Smart Mesh 설명회 </h1>
                  </div>
                  <div className="vw-title-right text-center tit2">
                    2024.07.19
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators"></div>
        </div>
        <div className="container marketing">
          <div className="row">
            <div className="position-relative col-lg-12">
              <div className="position-absolute m-2">
                <div className="col-12">
                  <span>금호전기 Smart Mesh 설명회</span>
                </div>
                <br />
                <div className="d-flex flex-wrap">
                  <img
                    src="photo/SmartMesh/0.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/0-1.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/1.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/1-1.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/1-2.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/2.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/3.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/4.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/5.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/6.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/7.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/8.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/9.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/10.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/11.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/12.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/13.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/14.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/15.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/16.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/17.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/18.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/19.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/20.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/21.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/22.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/23.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/24.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />
                  <img
                    src="photo/SmartMesh/25.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />

                  <img
                    src="photo/SmartMesh/27.jpg"
                    width="45%"
                    height="500px"
                    className="rounded me-3 mt-3 col-12 col-md-5  img-fluid"
                  />

                  <iframe
                    className="mt-3 col-md-11"
                    width="90%"
                    height="700"
                    src="https://www.youtube.com/embed/svLu5W1N9Jg?si=i2DIU4x7SLapkOxo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    className="mt-3 col-md-11"
                    width="90%"
                    height="700"
                    src="https://www.youtube.com/embed/8dOSlJuykwI?si=skndUtKZMDTvPUmp"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="col-12">
                  <CommentBox boardidx="0001" boardseq="3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
}
export default View5;
