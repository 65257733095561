import NavBar from "./NavBar.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../assets/CSS/carousel.css";
import "../assets/CSS/View.css";
import CommentBox from "./board/CommentBox.js";
import CheckLogin from "../utils/CheckLogin.js";
import { Link } from "react-router-dom";

function RelayToon5() {
  return (
    <div>
      <CheckLogin />
      <header>
        <NavBar />
      </header>
      <main>
        <div
          id="myCarousel test"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="photo/RelayToon/RelayToon.png"
                width="100%"
                height="100%"
              />
              <div className="container">
                <div className="carousel-caption text-bg-light vw-ct-title">
                  <div className="vw-top text-start tit1">
                    <span className="ms-5">
                      릴레이툰 #영업전략팀#영업전략소싱팀
                    </span>
                  </div>
                  <div className="center">
                    <h1 className="vw-title">
                      금호전기 릴레이툰(영업전략팀 👩‍💼👨‍💼)!!!!
                    </h1>
                  </div>
                  <div className="vw-title-right text-center tit2">2024.07</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators"></div>
        </div>
        <div className="container marketing">
          <div className="row text-center">
            <div className="col-12">
              <Link className="btn btn-danger" to={`/Relaytoon4`}>
                이전작
              </Link>
              <button
                className="btn btn-secondary ms-3"
                to={`/Relaytoon4`}
                disabled
              >
                다음작
              </button>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12">
              <div>
                <div className="col-12">
                  <span>
                    영업전략팀 이어나간 릴레이툰을 감상하시죠!! 💪💪💪💪
                    <br />
                    그린순서 ( 1. 백종현 과장님 2. 한현규 팀장님 3. 구기현
                    파트장님 4. 이호 과장님 5. 박은후 사원님 6. 이림천 과장님 7.
                    최훈일 차장님 8. 백종현 과장님 9. 박은후 사원님 10. 백종현
                    과장님 )
                  </span>
                </div>
                <br />
                <img
                  src="photo/RelayToon/SalesTeam.jpg"
                  width="100%"
                  height="100%"
                  className="rounded border"
                />
                <img
                  src="photo/RelayToon/SalesTeam2.jpg"
                  width="100%"
                  height="100%"
                  className="rounded border"
                />
                <div className="mt-2 mb-5">
                  <span>다음 릴레이툰 주자는 영업1팀입니다!!👨‍💼!!!</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <CommentBox boardidx="0002" boardseq="5" />
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
}

export default RelayToon5;
