import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useState, useEffect } from "react";
import { APIURL } from "../../utils/utilKumho";

function BoardSelect({ onChange, className, boardidx, disabled }) {
  //옵션을 불어와서 쭉 늘어주면됨
  //asp하듯 하면되져 map써서
  //아니면 얘는 틀만 두고 axios는 다른곳에서 실행하고 받아온걸 늘어놓을까
  //select 쓸게 그렇게 많나 툴로 지정할정도로??
  //예 그냥 불라올게유
  const [boardNm, setboardNm] = useState([]);
  const [selValue, setSelValue] = useState("");
  useEffect(() => {
    getData();
    if (boardidx) setSelValue(boardidx); // 수정글일 경우 해당 SelValue를 SET
  }, []);
  async function getData() {
    try {
      const response = await axios.get(
        //"http://api.raycure.co.kr:3838/SaleDayData/getCmmData/",
        APIURL("getBoard"),
        {
          params: {
            type: "BDType",
          },
        }
      );
      setboardNm(response.data);
    } catch (error) {
      console.error(error);
    }
  }
  const handleSelChange = (e) => {
    if (onChange) {
      onChange(e);
      setSelValue(e.target.value);
    }
  };
  return (
    <div className={`${className}`}>
      <FloatingLabel controlId="floatingSelect" label="게시판 종류">
        <Form.Select
          id="selBoard"
          aria-label="Floating label select example"
          onChange={handleSelChange}
          value={selValue}
          disabled={disabled === "Y" ? true : false}
        >
          <option value="">--게시판을 선택하세요--</option>
          {boardNm.map((BoardData) => (
            <option value={BoardData.board_idx} key={BoardData.board_idx}>
              {BoardData.board_name}
            </option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </div>
  );
}

export default BoardSelect;
